import { Button, Heading, Input, Textarea, useToast } from "@chakra-ui/react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { apiFetch } from "../utility/userFetch"

export default function KioskReview({ kioskId }: { kioskId: string }) {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)
  const [didSendRanking, setDidSendRanking] = useState(false)

  const onSubmit = (data: any) => {
    setIsLoading(true)
    apiFetch("ReviewPost", {
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        review: data.review,
        kioskId,
      }),
    })
      .then(a => {
        toast({
          title: "Uw review is verstuurd.",
          description: " Wij streven er naar om binnen een week te reageren.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        setIsSubmited(true)
      })
      .finally(() => setIsLoading(false))
  }

  const { register, handleSubmit, errors } = useForm()
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="kioskContainerStandAlone"
        style={{
          backgroundColor: "#EEEFF4",
          fontSize: "0.875em",
          marginBottom: "20px",
          border: "none",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Heading size="md" style={{ height: "1.5rem" }}>
            Wat is uw ervaring?
          </Heading>
         
        
            <>
              {!isSubmited && (
                <>
              
                  <div>
                    <Textarea
                      ref={register({ required: true })}
                      style={{ backgroundColor: "#fff" }}
                      name="review"
                      placeholder="Hoe was het bezoek?"
                    ></Textarea>
                  </div>
                  <div style={{ display: "flex", gap: "1rem" }}>
                    <Input
                      name="name"
                      ref={register({ required: true })}
                      style={{ backgroundColor: "#fff" }}
                      placeholder="Bezoekersnaam *"
                    />

                 
                  </div>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#71ba6f ", color: "#fff" }}
                    isLoading={isLoading}
                  >
                    Plaats review
                  </Button>
                </>
              )}
              {isSubmited && <>Bedankt voor uw inbreng!</>}
            </>
          
        </div>
      </div>
    </form>
  )
}
