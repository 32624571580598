import { Button, Heading } from "@chakra-ui/react"
import { Link } from "gatsby"
import queryString from "query-string"
import React, { useContext, useEffect } from "react"
import { KioskCardDistance } from "../components/KioskCardDistance"
import { KioskCardOpeningHours } from "../components/KioskCardOpeningHours"
import { KioskCardProducts } from "../components/KioskCardProducts"
import { KioskCardSocialMedia } from "../components/KioskCardSocialMedia"
import EditorBanner from "../components/kioskEditor/editorBanner"
import KioskPicture from "../components/kioskPicture"
import KioskReview from "../components/kioskReview"
import KioskRow from "../components/kioskRow"
import { KioskRowAddress } from "../components/KioskRowAddress"
import { KioskStar } from "../components/kioskStar"
import Layout from "../components/layout/layout"
import SearchLayout from "../components/maps/searchLayoutBase"
import { ProductPage } from "../components/ProductPage"
import UserContext from "../context/userContext"
import useHasKioskAccess from "../hooks/useHasKioskAccess"
import { Kiosk, KioskMessage, Product } from "../interfaces/kiosk"
import { stringToDate } from "../utility/date"
import { euro } from "../utility/string"

const LocationTemplate = ({ data, pageContext, location }: any) => {
  const {
    node,
    links,
    tags,
    neighbours,
    messages,
    products,
  }: {
    node: Kiosk
    links: { name: string; slug: string }[]
    tags: any
    places: any
    neighbours: Kiosk[]
    messages?: KioskMessage[]
    products: Product[]
  } = pageContext

  const selectedPlace = node.address.area ?? node.address.province
  const { state, dispatch } = useContext(UserContext)

  useEffect(() => {
    dispatch({
      ...state,
      search: { ...state?.search, location: selectedPlace },
    })
  }, [])
  const kiosks = [node, ...neighbours]

  const parsed = queryString.parse(location.search)
  const activateCode = parsed.id
  const hasAccess = useHasKioskAccess(node.id)
  return (
    <Layout
      title={`${node.name}, ${node.address.city}`}
      location={location}
      picture={node.picture ? "images/" + node.picture : undefined}
      description={node.information.description}
      kiosk={node}
      pageContext={pageContext}
    >
      <div>
        {activateCode && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "1.5rem",
            }}
          >
            <Link to={"/doemee?id=" + activateCode}>
              <Button colorScheme="red" variant="solid">
                Claim het account en bewerk de gegevens.
              </Button>
            </Link>
          </div>
        )}
      </div>
      <EditorBanner kioskId={node.id} products={tags} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "1rem",
          gap: "1rem",
          flexWrap: "wrap",
          padding: "0.5rem",
        }}
      >
        {node.picture && (
          <div
            className="mobileCenter"
            style={{
              width: "175px",
              height: "175px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <KioskPicture picture={node.picture} size={1} loading="eager" />
          </div>
        )}
        <div style={{ height: "5rem" }}>
          <div>
            <Heading as="h1">{node.name}</Heading>
          </div>
          <div>
            {" "}
            {links.map(({ name, slug }) => (
              <span key={slug}>
                <Link to={slug} className="link">
                  <span>{name}</span>
                </Link>
                ,{" "}
              </span>
            ))}
            <KioskCardDistance kiosk={node} />
          </div>
        </div>

        <div
          style={{ marginLeft: "auto", height: "1rem" }}
          className="notSmall"
        >
          <KioskStar kioskId={node.id} />
        </div>
      </div>

      <SearchLayout
        pageContext={pageContext}
        kiosks={kiosks}
        prefixSearch={{ location: selectedPlace }}
        doNotSearchOnInit
        selectedKioskId={node.id}
        gpsCoordinates={node.address.coordinates}
        noLocationBanner
        loadOnscroll
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              width: "var(--itemWidth)",
            }}
          >
            <div className="kioskContainerStandAlone">
              <div style={{ marginBottom: "1.5rem" }}>
                {node.information.description}{" "}
                {node.information.acceptedPayments}
              </div>
              <div>
                <KioskRowAddress kiosk={node} />
                <KioskCardOpeningHours
                  a={node}
                  style={{ marginTop: "1.5rem" }}
                />
                <KioskCardProducts
                  kiosk={node}
                  style={{ marginTop: "1.5rem" }}
                />
                {node.information.deliveryText && (
                  <KioskRow label="Bezorgen" style={{ marginTop: "1.5rem" }}>
                    {node.information.deliveryText}
                  </KioskRow>
                )}

                {node.information.deliveryTime && (
                  <KioskRow label="Bezorg tijd">
                    {node.information.deliveryTime}
                  </KioskRow>
                )}
                {(node.information.deliveryCost ||
                  node.information.freeDeliveryAmount) && (
                  <KioskRow label="Bezorg kosten">
                    {node.information.deliveryCost &&
                      euro(node.information.deliveryCost)}
                    {node.information.freeDeliveryAmount &&
                      " Gratis vanaf " +
                        euro(node.information.freeDeliveryAmount)}
                  </KioskRow>
                )}
                {node.information.phoneNumber && (
                  <KioskRow label="Telefoon" style={{ marginTop: "1.5rem" }}>
                    {node.information.phoneNumber}
                  </KioskRow>
                )}
                <KioskCardSocialMedia kiosk={node} />
                {!hasAccess && !activateCode && (
                  <Link
                    className="notSmall"
                    style={{
                      textDecoration: "underline",
                      marginTop: "2rem",
                      display: "block",
                    }}
                    to={"/forget/?id=" + node.id}
                  >
                    Dit is mijn kraampje
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "var(--itemWidth)",
            }}
            className="mobileTop"
          >
            {messages?.map(x => (
              <div
                key={x.RowKey}
                className="kioskContainer"
                style={{
                  backgroundColor: "#F1F8F0",
                  fontSize: "0.875em",
                  marginBottom: "20px",
                  border: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "1rem",
                  }}
                >
                  {x.imageUrl && (
                    <div style={{ width: "5rem" }}>
                      <KioskPicture
                        picture={x.imageUrl}
                        size={3}
                        loading="eager"
                      />
                    </div>
                  )}
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <Heading size="xs" style={{ minHeight: "1.1rem" }}>
                        {x.title}
                      </Heading>
                      <span style={{ fontSize: "90%" }}>
                        {stringToDate(x.publishDate)}
                      </span>
                    </div>
                    <span>{x.message}</span>
                  </div>
                </div>
              </div>
            ))}
            <KioskReview kioskId={node.id} />
            {products.length !== 0 && (
              <Heading
                size="md"
                as="h2"
                style={{ margin: "1.5rem 0", height: "1.1rem" }}
              >
                Producten
              </Heading>
            )}
            {products.map(product => (
              <ProductPage product={product} key={product.RowKey} />
            ))}
          </div>
        </div>
        <Heading
          size="md"
          as="h2"
          style={{ margin: "1.5rem 0", height: "1.1rem" }}
        >
          In de buurt van {node.name}
        </Heading>
      </SearchLayout>
    </Layout>
  )
}

export default LocationTemplate

// export const query = graphql`
//   query MyQuery2 {
//     file(relativePath: { eq: "apple.jpg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         fluid {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `
