import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react"
import React from "react"
import { KioskEditorModalProps } from "./kioskEditor"
import KioskEditorHoc from "./kioskEditorHoc"

export default function KioskEditorModal(input: KioskEditorModalProps) {
  const { overlayControl } = input
  return (
    <Modal
      onClose={overlayControl.onClose}
      size="full"
      isOpen={overlayControl.isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <KioskEditorHoc {...input} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={overlayControl.onClose}>Sluit</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
