import { Button } from "@chakra-ui/button"
import { Heading } from "@chakra-ui/layout"
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/popover"
import { navigate } from "gatsby"
import React from "react"
import useBasket from "../hooks/useBasket"
import useIsAdmin from "../hooks/useisAdmin"
import { Product } from "../interfaces/kiosk"
import KioskPicture from "./kioskPicture"

export const ProductPage = ({ product }: { product: Product }) => {
  const hasOrder = useIsAdmin()

  const { addToBasket, isInBasket } = useBasket()

  const item = {
    productId: product.RowKey,
    kioskId: product.PartitionKey,
    quantity: 1,
  }

  const basketItem = isInBasket(item)
  return (
    <div
      className="kioskContainer"
      style={{
        backgroundColor: "#F1F8F0",
        fontSize: "0.875em",
        marginBottom: "20px",
        border: "none",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          gap: "1rem",
        }}
      >
        {/* {x.imageUrl && (
                    <div style={{ width: "5rem" }}>
                      <KioskPicture picture={x.imageUrl} size={3} />
                    </div>
                  )} */}
        <div style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Heading size="xs" style={{ minHeight: "1.1rem" }}>
              {product.productTitle}
            </Heading>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "0.3rem" }}>
            {product.ImageUrl && (
              <div style={{ width: "5rem" }}>
                <KioskPicture
                  picture={product.ImageUrl}
                  size={3}
                  loading="eager"
                />
              </div>
            )}
            <div>{product.productDescription}</div>
            {hasOrder && (
              <Popover>
                <PopoverTrigger>
                  <Button>Bestel</Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />{" "}
                  {!basketItem ? (
                    <>
                      <PopoverHeader>Plaats in het mandje</PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            console.log(item)
                            addToBasket(item)
                          }}
                        >
                          Plaats
                        </Button>
                      </PopoverBody>
                    </>
                  ) : (
                    <>
                      <PopoverHeader>Plaats in het mandje</PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Button
                          colorScheme="blue"
                          onClick={() => {
                            navigate("/afrekenen")
                          }}
                        >
                          Afrekenen en ontvangen
                        </Button>
                      </PopoverBody>
                    </>
                  )}
                </PopoverContent>
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
