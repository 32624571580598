import React, { useContext, useMemo } from "react"
import UserContext from "../context/userContext"
import { Kiosk } from "../interfaces/kiosk"
import calcDistance from "../utility/distance"

export function KioskCardDistance({ kiosk }: { kiosk: Kiosk }) {
  const { state } = useContext(UserContext)
  const distance = useMemo(
    () => calcDistance(kiosk?.address?.coordinates, state?.coordinates),
    [kiosk?.address?.coordinates, state?.coordinates]
  )
  return <>{kiosk.address.coordinates && (distance ?? "...") + " KM"} </>
}
