export default function calcDistance(l?: { lat: number; lon: number }, r?: { lat: number; lon: number }) {

  if (!l || !r) {
    return;
  }

  var R = 6371; // km
  var dLat = toRad(r.lat - l.lat);
  var dLon = toRad(r.lon - l.lon);
  var lat1 = toRad(l.lat);
  var lat2 = toRad(r.lat);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return Math.round(d);
}

// Converts numeric degrees to radians
function toRad(Value: number) {
  return Value * Math.PI / 180;
}