import { Button, useDisclosure } from "@chakra-ui/react"
import React, { Suspense, useCallback, useState } from "react"
import useHasKioskAccess from "../../hooks/useHasKioskAccess"
import KioskEditorModal from "./kioskEditorModal"
export default function EditorBanner({
  products,
  kioskId,
}: {
  kioskId: string
  products: any
}) {
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const overlayControl = useDisclosure()

  const onClick = useCallback(() => {
    setIsEditorOpen(true)
    overlayControl.onOpen()
  }, [overlayControl, setIsEditorOpen])
  const hasAccess = useHasKioskAccess(kioskId)

  if (!hasAccess) {
    return <></>
  }
  return (
    <>
      {isEditorOpen && (
        <Suspense fallback={<></>}>
          <KioskEditorModal
            kioskId={kioskId}
            overlayControl={overlayControl}
            products={products}
          />
        </Suspense>
      )}
      {
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "2rem",
          }}
        >
          <Button colorScheme="red" variant="solid" onClick={onClick}>
            Bewerk locatie
          </Button>
        </div>
      }
    </>
  )
}
